import React from 'react'
import Navbar from '../inc/Navbar'
import Walltiles from './Walltiles'

function Walltilespage() {
  return (
    <div>
        <Navbar/>
        <br/>
        <br/>
        <br/>
        <br/>
        <Walltiles/>
    </div>
  )
}

export default Walltilespage
