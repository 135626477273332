import React from 'react'
import Image from "../images/Srijon Marble Main Building.jpg";
import Srijontiles from "../images/main banner.jpg";
import Srijonbasin from "../images/Srijan Marble office.jpg";
import betailmarble from "../images/Srijan & Sreenidhi Marble.jpg";

function Slider() {
  return (
    <div>
      <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
        <div className="carousel-inner">
          <div className="carousel-item active">
            <div className='borderradius'>
              <img src={Image} className="d-block w-100" alt="srijonmarble" />
            </div>
          </div>
          <div className="carousel-item">
            <div className='borderradius'>
              <img src={Srijontiles} className="d-block w-100" alt="Betail marble" />
            </div>
          </div>
          <div className="carousel-item">
            <div className='borderradius'>
              <img src={Srijonbasin} className="d-block w-100" alt="betail tiles" />
            </div>
          </div>
          <div className="carousel-item">
            <div className='borderradius'>
              <img src={betailmarble} className="d-block w-100" alt="Betail marble" />
            </div>
          </div>
        </div>
        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  )
}

export default Slider
