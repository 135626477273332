
import './App.css';
import {BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Home from "./components/pages/Home";
import Floor from "./components/pages/Floor";

import Outdoor from "./components/pages/Outdoor";
import Footer from "./components/inc/Footer";
import Contact from "./components/pages/Contact";
import Marbel from "./components/pages/Marbel";
import Walltilespage from './components/pages/Walltilespage';

function App() {
  return (
    <Router>
      
  
 
      <div>
    
       
        
        <Routes>
   
       
        <Route axact path="/" element={<Home/>}/>
        <Route axact path="/Floortiles" element={<Floor/>}/>
        <Route axact path="/Walltiles" element={<Walltilespage/>}/>
        <Route axact path="/Outdoortiles" element={<Outdoor/>}/>
          <Route axact path="/Contact" element={<Contact/>}/>
          <Route axact path="/Marble" element={<Marbel/>}/>
        </Routes>
    <Footer/>
  
    </div>
   
    </Router>
   
  );
}

export default App;
